.MuiFormControl-root {
  > label {
    margin-bottom: 2px;
  }
  .MuiFormHelperText-root {
    margin: 2px 0px 0px;
    font-size: 14px;
  }
}

.MuiPagination-root {
  .MuiPaginationItem-root {
    color: #fff !important;
  }
}

.MuiTableContainer-root {
  margin: 24px 0px;
  background: #0c152480 !important;

  .MuiTableRow-root:hover {
    background-color: #00000006;
  }

  .MuiTableBody-root > .MuiTableRow-root:last-child > .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableCell-root {
    border-color: #354a67;
  }

  .MuiTableCell-head {
    padding: 12px 12px;
    background: linear-gradient(0deg, #b68d23, #b68d23);
    color: #fff;
  }
  .MuiTableCell-body {
    padding: 12px 12px;
    color: #fff;
  }
}

.MuiDialog-root {
  .MuiDialogTitle-root {
    padding: 16px 24px;
    font-weight: 400;
  }
  .MuiDialogContent-root {
    min-height: 120px;
    padding: 32px 24px !important;
  }
  .MuiDialogActions-root {
    padding: 12px 16px;
  }
  .MuiDialogContent-dividers {
    border-top-color: #51565e;
  }
}

.MuiTabs-root {
  .MuiTabs-flexContainer {
    gap: 16px;
  }
  .MuiTab-root {
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;
    min-width: 150px;
    min-height: 45px;
    opacity: 1;
    background: url('/assets/images/Image.Board-Unactive.png') center / cover no-repeat;
  }

  .Mui-selected,
  .MuiTab-root:hover {
    background: url('/assets/images/Image.Board-Active.png') center / cover no-repeat;
  }
}

.MuiAccordion-root {
  &.MuiPaper-root::before {
    content: none;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}

.MuiLoadingButton-root {
  .MuiLoadingButton-loadingIndicator {
    position: static;
    left: 0px;
    margin-left: 4px;
    color: #fff9 !important;
  }

  &.MuiLoadingButton-loading {
    color: #fff9 !important;
  }
}

.MuiButton-root {
  &.MuiButton-outlinedInherit {
    border-color: #0008;
    color: #000b;
  }
}

.SnackbarContainer-top {
  &.SnackbarContainer-right {
    top: 72px !important;
  }

  .SnackbarContent-root {
    .SnackbarItem-message {
      font-size: 16px;
      font-family: 'Rubik';

      .MuiSvgIcon-root {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.MuiSelect-select {
  padding: 4px 32px 4px 16px !important;
  font-weight: normal !important;
  font-family: 'Rubik' !important;
  color: #fff !important;
  font-size: 16px !important;
}
