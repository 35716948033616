.walletconnect-modal__base {
  .walletconnect-modal__mobile__toggle {
    color: #888;
    > a {
      cursor: pointer;
    }
  }
  .walletconnect-qrcode__image {
    cursor: auto;
  }
}

@for $i from 1 through 12 {
  .max-line-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.go-jump {
  animation: jump ease-in-out 1.5s infinite alternate;
}

@keyframes run-left {
  0% {
    left: 0;
    transform: translateX(0);
  }
  100% {
    left: 5%;
    transform: translateX(-5%);
  }
}

.run-left {
  animation: run-left ease-in-out 3s infinite alternate;
}

@keyframes run-right {
  0% {
    right: 0;
    transform: translateX(0);
  }
  100% {
    right: 5%;
    transform: translateX(5%);
  }
}

.run-right {
  animation: run-right ease-in-out 3s infinite alternate;
}

@keyframes left {
  to {
    transform: translateX(-50%);
  }
}

.go-left {
  transform: translateX(0%);
  animation: left 30s linear infinite alternate;
}

.mobile-nav {
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    height: 10vh;
    align-items: stretch;
    position: fixed;
    inset: 80px 0 0 0;
    flex-direction: column;
    background-color: #1b2333 !important;
    border-bottom: 1px solid silver;
    z-index: 999;
    transition: all 0.3s;

    > a {
      padding: 12px 0px;
      border-bottom: 1px solid #dedede;
    }
  }

  @media (min-width: 1024px) {
    display: flex !important;
  }
}

.hamburger-icon {
  @media (min-width: 1024px) {
    display: none !important;
  }
}

.container-scroll {
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.container-scroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
