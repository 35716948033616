@import 'styles/component';
@import 'styles/mui';
@import '~aos/dist/aos.css';

body {
  font-size: 1rem;
  font-family: 'Rubik' !important;
  color: #fff;
}

body,
a,
button {
  cursor: url(assets/icons/Icon.Cursor.webp) 0 0, auto !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

body > iframe {
  display: none;
}

:root {
  --color-primary-light: #e8d231;
  --color-primary-main: #eeb73d;
  --color-primary-dark: #eeb73d;

  --color-secondary-light: #fff;
  --color-secondary-main: #fff;
  --color-secondary-dark: #fff;
}

.treasury {
  width: 900px;
  max-width: 100%;
  background: var(--linear, linear-gradient(90deg, rgba(255, 252, 252, 0.00) 0%, rgba(255, 213, 105, 0.20) 50%, rgba(246, 246, 246, 0.00) 100%));
  padding: 12px 0px;
  img {
    margin-right: 24px;
  }
}

.text {
  .label {
    color: var(--neutral-04, #6F767E);
    font-size: 15px;
    line-height: 22px;
  }
  .value {
    color: var(--neutral-00, #FFF);
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
  }
  @media (max-width: 640px) {
    .label {
      font-size: 12px;
      line-height: 15px;
    }
    .value {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.divider {
  height: 48px;
  background: #6f767e;
  width: 1px;
}

